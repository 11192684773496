import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import useSiteMetadata from '../components/SiteMetadata'

export const ShopPageTemplate = ({
  content,
  contentComponent,
  helmet,
  title,
  description,
  price,
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <PostContent content={content} />
            <h1>{title}</h1>
            <div className="is-size-5">{price}円</div>
            <div className="product-description">{description}</div>
            <div className="columns is-mobile is-centered">
              <div className="column">
                <Link
                  className="button is-dark order-button"
                  to={`/order?product=${encodeURIComponent(title)}`}
                >
                  メールで注文
                </Link>
              </div>
            </div>
            <div className="content-footer columns is-mobile section">
              <div className="content-footer__list column">
                <a onClick={() => window.history.back()}>Back to list</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

ShopPageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  price: PropTypes.number,
  helmet: PropTypes.object,
}

const ShopPage = ({ data, props }) => {
  const { markdownRemark: post } = data
  const { siteUrl } = useSiteMetadata()

  return (
    <Layout>
      <ShopPageTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        price={post.frontmatter.price}
        helmet={
          <Helmet titleTemplate="%s | plat">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
            <meta
              property="og:title"
              content={`${post.frontmatter.title} | plat`}
            />
            <meta
              property="og:image"
              content={`${siteUrl}${post.frontmatter.featuredimage.childImageSharp.fluid.src}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

ShopPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default ShopPage

export const pageQuery = graphql`
  query ShopPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        price
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
